module.exports = {
  title: process.env.VUE_APP_System_Name,
  // api路径
  BASE_URL: process.env.VUE_APP_BASE_URL,
  // 网上营业厅URL, 用户留言
  WT_URL: process.env.VUE_APP_WT_URL,
  // 图片资源地址
  CDN_URL: process.env.VUE_APP_CDN_URL,
  ORIGIN_URL:process.env.VUE_APP_ORIGIN,
  sysImageUrl:process.env.VUE_APP_System_ImageUrl,
  appId:process.env.VUE_APP_App_Id,
  SystemUrl:process.env.VUE_APP_System_Url,
  SystemName:process.env.VUE_APP_App_Sys_Name,
}
